import {infoClient, infoClientBSC, infoClientETH, infoStableSwapClient} from 'utils/graphql'
import {
  INFO_CLIENT,
  INFO_CLIENT_ETH,
  BLOCKS_CLIENT,
  BLOCKS_CLIENT_ETH,
  BLOCKS_CLIENT_BSC, INFO_CLIENT_BSC
} from 'config/constants/endpoints'
import { ChainId } from '@pancakeswap/sdk'
import {
  PCS_ETH_START,
  ETH_TOKEN_BLACKLIST,
  TOKEN_BLACKLIST,
  BSC_TOKEN_BLACKLIST, PCS_MEER_START , PCS_BSC_START
} from 'config/constants/info'

export type MultiChainName = 'BSC' | 'ETH' | 'MEER'

export const multiChainQueryMainToken = {
  BSC: 'BNB',
  ETH: 'ETH',
  MEER: 'MEER',
}

export const multiChainBlocksClient = {
  BSC: BLOCKS_CLIENT_BSC,
  ETH: BLOCKS_CLIENT_ETH,
  MEER:BLOCKS_CLIENT
}

export const multiChainStartTime = {
  MEER: PCS_MEER_START,
  BSC: PCS_BSC_START,
  ETH: PCS_ETH_START,
}

export const multiChainId = {
  BSC: ChainId.BSC,
  ETH: ChainId.ETHEREUM,
  MEER: ChainId.QITMEER_EVM
}

export const multiChainPaths = {
  [ChainId.QITMEER_EVM]: '',
  [ChainId.BSC]: '/bsc',
  [ChainId.ETHEREUM]: '/eth',
}

export const multiChainQueryClient = {
  BSC: infoClientBSC,
  ETH: infoClientETH,
  MEER:infoClient
}

export const multiChainQueryEndPoint = {
  BSC: INFO_CLIENT_BSC,
  ETH: INFO_CLIENT_ETH,
  MEER: INFO_CLIENT,
}

export const multiChainScan = {
  BSC: 'BscScan',
  ETH: 'EtherScan',
  MEER: 'MeerScan',
}

export const multiChainTokenBlackList = {
  BSC: BSC_TOKEN_BLACKLIST,
  ETH: ETH_TOKEN_BLACKLIST,
  MEER: TOKEN_BLACKLIST,
}

export const getMultiChainQueryEndPointWithStableSwap = (chainName: MultiChainName) => {
  const isStableSwap = checkIsStableSwap()
  if (isStableSwap) return infoStableSwapClient
  return multiChainQueryClient[chainName]
}

// export const checkIsStableSwap = () => window.location.href.includes('stableSwap')
export const checkIsStableSwap = () => false
