import { getAddress } from '@ethersproject/address'
import memoize from 'lodash/memoize'
import { ChainId, Token } from '@pancakeswap/sdk'

const mapping = {
  [ChainId.BSC]: 'smartchain',
  [ChainId.ETHEREUM]: 'ethereum',
}

const getTokenLogoURL = memoize(
  (token?: Token) => {
    if (token && mapping[token.chainId]) {
      return `https://assets-cdn.trustwallet.com/blockchains/${mapping[token.chainId]}/assets/${getAddress(
        token.address,
      )}/logo.png`
    }
    return `https://www.candyswap.network/images/tokens/${getAddress(token.address)}.png`
  },
  (t) => `${t.chainId}#${t.address}`,

)

// const getTokenLogoURL = (token?: Token) => {
//     if (token && mapping[token.chainId]) {
//         return `https://assets-cdn.trustwallet.com/blockchains/${mapping[token.chainId]}/assets/${token.address}/logo.png`
//     }
//     return `https://www.candyswap.network/images/tokens/${token.address}.png`
// }

export default getTokenLogoURL
